@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  overflow-x: hidden;
}

body {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Work Sans", sans-serif !important;
  background-color: #fff;
}

* {
  outline: none;
}

a {
  text-decoration: none;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s
}

p {
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.container {
  max-width: 1350px;
  padding: 0px 30px;
}

.menu_sidebar .logo_box {
  text-align: center;
  margin-bottom: 50px;
}

.menu_sidebar .logo_box img {
  height: auto;
  width: 100%;
}

.menu_sidebar {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 220px;
  min-width: 220px;
  padding: 15px;
  -webkit-transition: all 0.01 ease-in-out;
  transition: all 0.01 ease-in-out;
  background: #d477c9;
  box-shadow: rgb(85, 85, 85) -3px 0px 5px 0px;
  transition: .5s ease all;
}

.menu_sidebar .menu_box ul {
  padding-left: 0px;
  list-style: none;
}

.menu_sidebar .menu_box ul li {
  margin-bottom: 0px;
}

.menu_sidebar .menu_box ul li a {
  border-radius: 0px;
  color: #1b2861;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  padding: 15px 0px;
  position: relative;
  white-space: inherit;
  word-break: break-all;
  border-bottom: 1px solid #ffffff36;
}

.menu_sidebar .menu_box ul li a:hover {
  background-color: #d477c94a;
}

.menu_sidebar .menu_box ul li a svg {
  color: #fff;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 10px;
}

.menu_sidebar .menu_box ul li a.active {
  color: #fff;
  background-color: #d477c9;
}

.menu_sidebar .menu_box ul li a .name_txt {
  color: #fff;
  font-size: 16px;
  vertical-align: middle;
}

.dashboard_body {
  padding-left: 220px;
  min-height: 600px;
}

.dashboard_body .tophead_mainsec > .row {
  align-items: center;
}

.dashboard_body .tophead_mainsec {
  padding: 10px 25px 10px;
  border-bottom: 1px solid #e4e4e4;
}

.title_mainsec h2 {
  color: #d477c9;
  font-size: 26px;
  font-weight: 600;
  line-height: 37px;
  margin-bottom: 0px;
}

.dashboard_body .tophead_mainsec .btn_mainsec {
  text-align: right;
}

.btn_mainsec .btn_style {
  background-color: #d477c9;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 20px;
  display: inline-block;
  text-transform: capitalize;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  overflow: hidden;
  cursor: pointer;
}

.btn_mainsec .btn_style:hover {
  color: #000;
  border-radius: 6px;
}

.btn_mainsec .btn_style::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border-radius: 6px;
}

.btn_mainsec .btn_style:hover::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.email_listing_mainsec {
  padding: 25px;
}

.table_mainsec .table thead tr th {
  color: #d477c9;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 15px 15px 0px;
  text-align: center;
}

.table_mainsec .table thead tr th:first-child {
  text-align: left;
}

.table_mainsec .table tbody tr td {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  padding: 15px 15px 15px 0px;
  text-align: center;
}

.table_mainsec .table tbody tr td:first-child {
  text-align: left;
}

.table_mainsec .table tbody tr td a {
  color: #000;
}

.table_mainsec .table tbody tr td a:hover {
  color: #d477c9;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle::after {
  border: none;
}

.profile_box_mainsec {
  position: absolute;
  bottom: 20px;
  left: 0px;
  text-align: center;
  width: 100%;
}

.profile_box_mainsec .nav-item.dropdown .nav-link {
  text-align: left;
  padding: 0px 15px;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle .profile_imgbox {
  display: inline-block;
  height: 50px;
  overflow: hidden;
  width: 50px;
  vertical-align: middle;
  margin-right: 10px;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle .profile_imgbox img {
  width: 100%;
  height: auto;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-menu {
  transform: translate(219px,11px) !important;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle .name_txt {
  color: #fff;
  vertical-align: middle;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-menu::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-right: 20px solid #fff;
  border-bottom: 12px solid transparent;
  position: absolute;
  left: -20px;
  bottom: 22px;
}

.profile_box_mainsec .dropdown-menu {
  margin-top: 0px;
  padding: 0px;
  border-radius: 6px;
  min-width: 220px;
}

.profile_box_mainsec .dropdown_toggle {
  display: block;
  padding-bottom: 5px;
}

.profile_box_mainsec:hover .dropdown-menu.profile-dropdown {
  display: block;
}

.profile_box_mainsec .dropdown-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  padding-top: 15px;
}

.profile_box_mainsec .dropdown-item {
  color: #727b83;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  padding: 12px 20px;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
}

.profile_box_mainsec li:last-child .dropdown-item {
  border-bottom: none;
}

.profile_box_mainsec .dropdown-item:hover {
  color: #fff;
  background-color: #d477c9;
}

.profile_box_mainsec .dropdown-item svg {
  font-size: 18px;
  vertical-align: middle;
  margin-right: 5px;
  color: #d477c9;
}

.profile_box_mainsec .dropdown-item:hover svg {
  color: #fff;
}

.profile_box_mainsec .dropdown-item span {
  color: #0b0b13;
}

.profile_box_mainsec .dropdown-item:hover span {
  color: #fff;
}

.addnew_emails_mainsec {
  padding: 25px;
}

.addnew_emails_mainsec .form_maisnec {
  padding-top: 20px;
  border-right: 1px solid #e1d9ff;
  padding-right: 70px;
  padding-bottom: 20px;
  position: relative;
}

.addnew_emails_mainsec .form_maisnec::after {
  content: "Or";
  width: 55px;
  height: 55px;
  background-color: #d477c9;
  border-radius: 50px;
  padding: 7px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  position: absolute;
  top: 50%;
  right: -56px;
  transform: translate(-50%,-50%);
  text-align: center;
  border: 5px solid #fff;
}

.form_group {
  margin-bottom: 20px;
}

.form_group .form-label {
  color: #d477c9;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 5px;
}

.form-control {
  background-color: initial;
  border: 1px solid #dedede !important;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  padding: 8px 20px;
  outline: none !important;
  box-shadow: none !important;
}

.addnew_emails_mainsec .csv_upload_boxsec {
  padding-top: 20px;
  padding-left: 0px;
  padding-bottom: 10px;
}

.addnew_emails_mainsec .csv_upload_boxsec .uploaded-file-name p {
  color: #fff;
}

.campaign_input_box lable {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

.campaign_input_box input {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: block;
  background-color: transparent;
}

.upload_box {
  margin-bottom: 20px;
}

.upload_box .upload_txt {
  border: 2px dashed #bcbcbc;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.upload_box {
  margin-bottom: 20px;
  position: relative;
}

.upload_box .form-control {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.upload_box .upload_txt {
  border: 2px dashed #bcbcbc;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.upload_box .upload_txt svg {
  color: #d477c9;
  font-size: 30px;
  margin-bottom: 10px;
}

.upload_box .upload_txt .contnt_txt {
  width: 100%;
}

.upload_box .upload_txt .contnt_txt p {
  margin-bottom: 0px;
}

.dashboard_body .tophead_mainsec .right_options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.dashboard_body .tophead_mainsec .right_options .filter_mainbox .form_group .form-control {
  padding: 8px 50px 8px 20px;
}

.dashboard_body .tophead_mainsec .right_options .form_group {
  margin-bottom: 0px;
}

.dashboard_body .tophead_mainsec .right_options .filter_mainbox .form_group .form-control {
  padding: 8px 40px 8px 20px;
  background-image: url("../img/down-icon.png");
  background-repeat: no-repeat;
  background-position: 96%;
  background-size: 24px;
  cursor: pointer;
}

.table_mainsec .more_options .dropdown .dropdown-toggle::after {
  content: "";
  border: none;
}

.table_mainsec .table tbody tr td a svg {
  font-size: 17px;
}

.more_options .dropdown .dropdown-menu {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 0px;
  min-width: 250px;
  overflow: hidden;
}

.more_options .dropdown .dropdown-menu li .dropdown-item {
  color: #323232;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  padding: 13px 25px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 13px;
}

.more_options .dropdown .dropdown-menu li .dropdown-item:hover {
  background-color: #d477c9;
  color: #fff;
}

.table_mainsec .table-responsive {
  min-height: 600px;
}

.table_mainsec .more_options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.table_mainsec .more_options .compaign_starstop svg {
  color: #d477c9;
  font-size: 22px;
  cursor: pointer;
}

.search_mainbox .form_group {
  position: relative;
}

.search_mainbox .form_group .search_icon {
  position: absolute;
  top: 10px;
  right: 14px;
}

.search_mainbox .form_group .form-control {
  border-radius: 50px;
  padding: 8px 40px 8px 25px;
}

.resume_paused_btn.btn_mainsec .btn_style svg {
  margin-right: 5px;
  font-size: 20px;
  vertical-align: middle;
}

.dashboard_body .analytics_mainsec {
  padding: 25px;
}

.compaign_status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.compaign_status .status_title {
  color: #181230;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.compaign_status .status_result {
  background-color: #d477c9;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  padding: 5px 15px;
}

.dashboard_body .analytics_mainsec .schedule_btn.btn_mainsec {
  text-align: right;
}

.analytics_mainsec .compaign_infosec {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin: 0px -10px;
  margin-top: 50px;
}

.analytics_mainsec .compaign_infosec .compaign_info_box {
  width: 20%;
  padding: 0px 10px;
}

.analytics_mainsec .compaign_infosec .compaign_innerbox {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 15px;
  text-align: center;
  min-height: 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.analytics_mainsec .compaign_infosec .compaign_info_box .title_sec {
  color: #d477c9;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  width: 100%;
}

.analytics_mainsec .compaign_infosec .compaign_info_box .result_sec {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  line-height: 37px;
}

.analytics_mainsec .step_analytics_table {
  margin-top: 50px;
}

.analytics_mainsec .step_analytics_table .title_sec h3 {
  color: #000;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

.analytics_mainsec .step_analytics_table .table_mainsec .table-responsive {
  min-height: 200px;
}

.schedule_modal .modal-dialog {
  max-width: 900px;
}

.schedule_modal .modal-header .modal-title {
  color: #d477c9;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
}

.form-group {
  margin-bottom: 25px;
}

.schedule_form .days_mainsec .form_group {
  margin-bottom: 5px;
}

.checkbox_mainsec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.custom_checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e9e4ff;
  border: 1px solid #d477c9;
  border-radius: 3px;
}

.custom_checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom_checkbox input:checked ~ .checkmark {
  background-color: #d477c9;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom_checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom_checkbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*.dashboard_mainbox {
    background: #fff;
}*/

.dashboard_body .services_mainbox {
  padding: 25px;
  position: relative;
}

.services_mainbox .services_box a {
  background: linear-gradient(180deg,#d477c9 0,#fff 86%);
  border: 1px solid #d477c9;
  border-radius: 6px;
  min-height: 180px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.services_mainbox .services_box a:hover {
  background: linear-gradient(180deg,#d477c9,#d477c94d 100%);
}

.services_mainbox .services_box .icon_box {
  width: 100%;
  text-align: center;
}

.services_mainbox .services_box .icon_box img {
  width: 80px;
  height: auto;
}

.services_mainbox .services_box .title_box h3 {
  color: #000;
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 0px;
}

.services_mainbox .services_box a:hover .title_box h3 {
  color: #000;
}

.t-c-pages{
  padding-top: 50px;
  padding-bottom: 50px;
}

.t-c-pages h1,.t-c-pages h2, .t-c-pages h3, .t-c-pages h4,.t-c-pages p{
    color: #000;
}

.campaign_details {
  padding: 25px;
  position: relative;
}

.campaign_details .title_sec h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 20px;
}

.campaign_details .numberbox_mainsec .inner_boxsec {
  background-color: #d477c9;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.campaign_details .numberbox_mainsec .inner_boxsec h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 0px;
  width: 100%;
}

.campaign_details .numberbox_mainsec .inner_boxsec p {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.campaign_details .status_mainsec {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}

.campaign_details .status_mainsec .status_innerbox {
  border-radius: 6px;
  position: relative;
  border: 2px solid #d477c9;
  min-height: 200px;
}

.campaign_details .status_mainsec .status_innerbox .titlebox {
  background-color: #d477c9;
  padding: 10px 20px;
}

.campaign_details .status_mainsec .status_innerbox h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 37px;
  margin-bottom: 0px;
  width: 100%;
}

.campaign_details .status_mainsec .status_innerbox .contntbox {
  padding: 20px;
}

.campaign_details .status_mainsec .status_innerbox h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 5px;
}

.campaign_details .status_mainsec .status_innerbox p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.campaign_details .status_mainsec .status_innerbox p:last-child {
  margin-bottom: 0px;
}

.progress_mainsec .progress_boxsec {
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.progress_mainsec .progress_boxsec .outbound_txt {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
}

.progress_mainsec .progress_boxsec .warmup_txt {
  color: #d477c9;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
}

.progress_mainsec .progress {
  height: 25px;
  border-radius: 50px;
  background-color: #f0c5eb;
}

.progress_mainsec .progress .progress-bar {
  background-color: #d477c9;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}

.progress_mainsec .date_box {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.progress_mainsec .date_box span {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}

.budge {
  background-color: #000;
  border-radius: 4px;
  padding: 0px 8px;
  display: inline-block;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.green {
  background-color: green;
}

.orange {
  background-color: orange;
}

.red {
  background-color: red;
}

.dashboard_mainbox.campaigns .tophead_mainsec .btn_mainsec .btn_style::before {
  background: #000;
}

.dashboard_mainbox.campaigns .tophead_mainsec .btn_mainsec .btn_style:hover {
  color: #fff;
}

.dashboard_mainbox .campaign_list {
  background-color: #fff;
  height: 100%;
}

.email_listing_mainsec .custom_checkbox {
  margin-bottom: 0px;
  padding: 0px;
  width: 25px;
  height: 25px;
}

.email_listing_mainsec .custom_checkbox .checkmark {
  background-color: #ffebfd !important;
  border: 1px solid #d477c9 !important;
  border-radius: 3px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 20px;
}

.email_listing_mainsec .custom_checkbox .checkmark::after {
  border-color: #d477c9;
}

.email_listing_mainsec .table_mainsec .table thead tr th {
  text-align: left;
  font-weight: 500;
}

.email_listing_mainsec .table_mainsec .table tbody tr td {
  text-align: left;
  color: #000;
}

.email_listing_mainsec .table_mainsec .table tbody tr td .action_btn {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.email_listing_mainsec .table_mainsec .table tbody tr td .action_btn .refresh_btn {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.email_listing_mainsec .table_mainsec .table tbody tr td .action_btn .remove_btn {
  background-color: initial;
  border: none;
  padding: 0;
}

.emailchecker_mainsec .step_mainsec .step_boxsec .title_box h3 {
  color: #000;
  font-size: 22px;
  font-weight: 500;
}

.emailchecker_mainsec .step_mainsec .step_boxsec .contnt_box p {
  color: #626262;
}

.emailchecker_mainsec .uploadbox_mainsec .fileinput_sec .upload_box .upload_txt .contnt_txt p {
  color: #000;
}

.emailchecker_mainsec .uploadbox_mainsec .fileinput_sec .btn_mainsec .btn_style:hover {
  color: #fff;
}

.emailchecker_mainsec .uploadbox_mainsec .fileinput_sec .btn_mainsec .btn_style::before {
  background-color: #000;
}

.emaildetails_page .tophead_mainsec .title_mainsec h2 span {
  font-size: 18px;
  font-weight: 400;
}

.emaildetails_mainsec {
  padding: 20px;
  position: relative;
}

.emaildetails_mainsec .tabs_mainsec .nav-tabs .nav-item {
  margin-right: 25px;
}

.emaildetails_mainsec .tabs_mainsec .nav-tabs .nav-item .nav-link {
  background-color: #fff;
  border: none;
  padding: 8px 2px;
  color: #292929;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 0px;
}

.emaildetails_mainsec .tabs_mainsec .nav-tabs .nav-item .nav-link.active {
  color: #d477c9;
  border-bottom: 3px solid #d477c9;
}

.emaildetails_mainsec .tabs_mainsec .tab-content .overview_contntbox {
  padding: 30px 0px;
}

.emaildetails_mainsec .overview_contntbox .title_mainsec {
  margin-bottom: 25px;
}

.emaildetails_mainsec .overview_contntbox .title_mainsec h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin-bottom: 50px;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox .innerbox {
  width: 25%;
  border-right: 1px solid #cec0ff;
  padding: 15px 30px;
  text-align: center;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox .innerbox h3 {
  color: #d477c9;
  font-size: 40px;
  font-weight: 500;
  line-height: 47px;
  margin-bottom: 0px;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox .innerbox p {
  color: #292929;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0px;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox .innerbox:nth-child(2) h3 {
  color: #00c800;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox .innerbox:nth-child(3) h3 {
  color: red;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox .innerbox:nth-child(4) h3 {
  color: orange;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox .innerbox:last-child {
  border-right: none;
}

.emaildetails_mainsec .emailoutbound._mainbox {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin-bottom: 50px;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox {
  width: 48%;
  padding: 15px;
  background: linear-gradient(to bottom, #d477c9 0%,#fff 50%);
  border: 1px solid #d477c9;
  border-radius: 6px;
  margin-right: 30px;
  min-height: 180px;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox:last-child {
  margin-right: 0px;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox .title_sec h4 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 20px;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox .emailperformance_box .contnt_sec h3 {
  color: green;
  font-size: 30px;
  font-weight: 600;
  line-height: 31px;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox .emailperformance_box .contnt_sec p {
  color: #292929;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 0px;
}

.outboundstatus_box .progress_mainsec .label_sec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.outboundstatus_box .progress_mainsec .label_sec span {
  color: #292929;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0px;
}

.outboundstatus_box .progress_mainsec .label_sec span:first-child {
  color: #d477c9;
}

.outboundstatus_box .progress_mainsec .progress {
  margin-bottom: 10px;
}

.progress_mainsec .progress .progress-bar {
  background-color: #d477c9;
}

.outboundstatus_box .progress_mainsec .date_sec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.outboundstatus_box .progress_mainsec .date_sec span {
  color: #292929;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 0px;
}

.overview_contntbox .graph_mainsec {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.overview_contntbox .graph_mainsec .inner_box.inboxspam_graph {
  width: 30%;
}

.overview_contntbox .graph_mainsec .inner_box.warmupemail_graph {
  width: 60%;
}

.overview_contntbox .graph_mainsec .inner_box .title_sec h4 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
}

.overview_contntbox .graph_mainsec .inner_box .graphbox img {
  width: 100%;
  height: auto;
}

.emaildetails_mainsec .general_contntbox {
  padding: 30px 0px;
}

.emaildetails_mainsec .general_contntbox .smtpsettings_mainsec {
  margin-bottom: 50px;
}

.emaildetails_mainsec .general_contntbox .title_mainsec {
  margin-bottom: 25px;
}

.emaildetails_mainsec .general_contntbox .title_mainsec h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.emaildetails_mainsec .general_contntbox .smtpsettings_form {
  width: 70%;
}

.general_contntbox .form-group label {
  color: #d477c9;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: block;
  margin-bottom: 5px;
}

.general_contntbox .number_radiobtn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.general_contntbox .number_radiobtn .number_input {
  width: 30%;
}

.general_contntbox .number_radiobtn .radiobtn_box {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.general_contntbox .number_radiobtn .radiobtn_box .custom_radiobtn {
  margin-bottom: 0px;
}

.general_contntbox .number_radiobtn .radiobtn_box .custom_radiobtn .checkmark {
  background-color: #ffebfd !important;
  border: 1px solid #d477c9 !important;
  border-radius: 50px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 20px;
}

.general_contntbox .number_radiobtn .radiobtn_box .custom_radiobtn .checkmark::after {
  background: #d477c9;
}

.emaildetails_mainsec .form-group .form-control {
  color: #000;
}

.smtpsettings_form .form-group.form-check .form-check-label {
  color: #292929;
  font-size: 16px;
  font-weight: 400;
}

.radiobtn_box .custom_radiobtn {
  position: relative;
}

.custom_radiobtn {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.radiobtn_box .custom_radiobtn .label_txt {
  color: #292929;
  font-size: 14px;
  font-weight: 400;
}

.custom_radiobtn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom_radiobtn .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.custom_radiobtn:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom_radiobtn input:checked ~ .checkmark {
  background-color: #d477c9;
}

.custom_radiobtn .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom_radiobtn input:checked ~ .checkmark:after {
  display: block;
}

.custom_radiobtn .checkmark::after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.emaildetails_mainsec .form-group.form-check {
  padding-left: 0px;
}

.emaildetails_mainsec .form-group.form-check .custom_checkbox {
  padding-left: 30px;
  color: #292929;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.emaildetails_mainsec .form-group.form-check .custom_checkbox .checkmark {
  background-color: #ffebfd !important;
  border: 1px solid #d477c9 !important;
  border-radius: 3px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 20px;
}

.emaildetails_mainsec .form-group.form-check .custom_checkbox .checkmark::after {
  border-color: #d477c9;
}

.emaildetails_mainsec .form-group.form-check .custom_checkbox .label_txt {
  display: inline-block;
  vertical-align: middle;
}

.smtpsettings_form .btn_boxsec {
  margin-top: 0px;
}

.smtpsettings_form .btn_boxsec .btn_style {
  background-color: #d477c9;
  border-radius: 4px;
  box-shadow: none;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 8px 30px;
}

.smtpsettings_form .btn_boxsec .btn_style:hover {
  background-color: #000;
  color: #fff;
}

.emaildetails_mainsec .warmup_contntbox {
  padding: 30px 0px;
}

.emaildetails_mainsec .warmup_contntbox .title_mainsec {
  margin-bottom: 0px;
}

.emaildetails_mainsec .warmup_contntbox .title_mainsec h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.emailwarmup_enabled_sec .custom_switch {
  margin-top: 30px;
}

.custom_switch .switch {
  position: relative;
  display: inline-block;
  padding-left: 60px;
  cursor: pointer;
}

.custom_switch .switch .label_txt {
  color: #292929;
  font-size: 14px;
  font-weight: 400;
  margin-top: -1px;
  display: block;
}

.custom_switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}

.custom_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c4b4ff;
  -webkit-transition: .4s;
  transition: .4s;
  width: 50px;
  height: 18px;
}

.custom_switch .slider::before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 0px;
  bottom: -4px;
  background-color: #d477c9;
  -webkit-transition: .4s;
  transition: .4s;
}

.custom_switch .switch input + .slider.round {
  background-color: #ffd1fa;
}

.custom_switch .switch input:checked + .slider.round {
  background-color: #ffd6fa;
}

.custom_switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.custom_switch .switch input + .slider.round::before {
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #9e9e9e;
}

.custom_switch .switch input:checked + .slider.round::before {
  background-color: #d477c9;
}

.custom_switch .slider.round {
  border-radius: 34px;
}

.custom_switch .slider.round:before {
  border-radius: 50%;
}

.warmup_contntbox .emailwarmup_enabled_sec p {
  font-size: 14px;
  color: #292929;
}

.emaildetails_mainsec .warmup_contntbox .emailwarmup_enabled_sec {
  border-bottom: 1px solid #e4e4e4;
  padding-top: 30px;
  padding-bottom: 30px;
}

.emaildetails_mainsec .email_number_sec {
  border-bottom: 1px solid #e4e4e4;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 80px;
}

.emaildetails_mainsec .email_number_sec .contnt_box h4 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0px;
}

.emaildetails_mainsec .email_number_sec .contnt_box p {
  font-size: 14px;
  color: #292929;
  margin-bottom: 0px;
}

.emaildetails_mainsec .email_number_sec .number_inputbox .form-control {
  width: 50%;
}

.emaildetails_mainsec .daily_rampup_sec .custom_switch {
  margin-top: 0px;
  margin-bottom: 20px;
}

.emaildetails_mainsec .daily_rampup_sec .custom_switch .switch .label_txt {
  color: #292929;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.emaildetails_mainsec .daily_rampup_sec .daily_rampup_input .form-group {
  width: 40%;
  margin-bottom: 0px;
}

.emaildetails_mainsec .daily_rampup_sec .daily_rampup_input .form-group label {
  color: #d477c9;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  display: block;
  margin-bottom: 5px;
}

.range_container {
  --_marker-border-clr: #d477c9;
  --_marker-size: 15px;
  --_track-heigt: 3px;
  --_tooltip-bg-clr: rgb(94, 56, 234);
  --_tooltip-txt-clr: #d477c9;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.sliders_control {
    position: relative;
}

.slider-tooltip {
  position: absolute;
  top: -3.5rem;
  left: 0;
  width: fit-content;
  background-color: rgb(94, 56, 234);
  color: #FFF;
  font-size: 0.8rem;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  text-align: center;
  translate: -50% 0;
}

.slider-tooltip::before {
    content: "";
    position: absolute;
    bottom: -0.25rem;
    left: 50%;
    translate: -50% 0;
    width: .5rem;
    height: .5rem;
    rotate: 45deg;
    z-index: -1;
    background-color: inherit;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: var(--_marker-size);
    height: var(--_marker-size);
    background-color: var(--_marker-border-clr);
    border-radius: 50%;
    box-shadow: 0 0 0 1px var(--_marker-border-clr);
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: var(--_marker-size);
    height: var(--_marker-size);
    background-color: var(--_marker-border-clr);
    border-radius: 50%;
    box-shadow: 0 0 0 1px var(--_marker-border-clr);
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb:hover {
    background: #f7f7f7;
}

input[type=range]::-webkit-slider-thumb:active {
    box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
    -webkit-box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: var(--_track-heigt);
    width: 100%;
    position: absolute;
    background-color: rgb(94, 56, 234);
    pointer-events: none;
}

#fromSlider {
    height: 0;
    z-index: 1;
}

.scale {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    position: relative;
    width: calc(100% - var(--_marker-size));
    margin-inline: auto;
    font-size: 0.8rem;
}

.scale div {
    position: absolute;
    translate: -50% 0;
}

.scale div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50% -125%;
    width: 1px;
    height: 10px;
    background-color:#666;
}

.emaildetails_mainsec .totalnumber_warmupemails .contnt_box {
  width: 100%;
}

.emaildetails_mainsec .totalnumber_warmupemails .range_container #scale {
  display: none;
}

.emaildetails_mainsec .reply_rate_sec .contnt_box {
  width: 100%;
  margin-bottom: 10px;
}

.emaildetails_mainsec .reply_rate_sec {
  gap: 0px;
}

.emaildetails_mainsec .reply_rate_sec .reply_rate_suggested {
  width: 40%;
}

.emaildetails_mainsec .reply_rate_sec .reply_rate_suggested .form-group label {
  color: #d477c9;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  display: block;
  margin-bottom: 5px;
}

.emaildetails_mainsec .warmup_identifier_sec {
  gap: 0px;
}

.emaildetails_mainsec .warmup_identifier_sec .contnt_box {
  width: 100%;
  margin-bottom: 20px;
}

.emaildetails_mainsec .warmup_identifier_sec .identifier_input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.emaildetails_mainsec .warmup_identifier_sec .identifier_input .form-group {
  margin-bottom: 0px;
}

.emaildetails_mainsec .warmup_identifier_sec .identifier_input .identifier_ans p {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0px;
}

.emaildetails_mainsec .checkbox_mainbox {
  gap: 0px;
}

.emaildetails_mainsec .checkbox_mainbox .custom_checkbox {
  margin-bottom: 0px;
  padding-left: 30px;
}

.emaildetails_mainsec .checkbox_mainbox .custom_checkbox .checkmark {
  background-color: #ffebfd !important;
  border: 1px solid #d477c9 !important;
  border-radius: 3px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 20px;
}

.emaildetails_mainsec .checkbox_mainbox .custom_checkbox .checkmark::after {
  border-color: #d477c9;
}

.emaildetails_mainsec .checkbox_mainbox .custom_checkbox .label_txt h4 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0px;
}

.emaildetails_mainsec .checkbox_mainbox .custom_checkbox .label_txt p {
  font-size: 14px;
  color: #292929;
  margin-bottom: 0px;
}

.emaildetails_mainsec .management_contntbox {
  padding: 30px 0px;
}

.emaildetails_mainsec .management_contntbox .add_tags_mainsec {
  border-bottom: 1px solid #e4e4e4;
  padding-top: 30px;
  padding-bottom: 30px;
}

.emaildetails_mainsec .management_contntbox .add_tags_mainsec:first-child {
  padding-top: 0px;
}

.emaildetails_mainsec .management_contntbox .add_tags_mainsec:last-child {
  border: none;
}

.emaildetails_mainsec .management_contntbox .add_tags_mainsec .title_sec {
  margin-bottom: 15px;
}

.emaildetails_mainsec .management_contntbox .add_tags_mainsec .title_sec h4 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0px;
}

.emaildetails_mainsec .management_contntbox .add_tags_mainsec .title_sec p {
  font-size: 14px;
  color: #292929;
  margin-bottom: 0px;
}

.emaildetails_mainsec .management_contntbox .add_tags_mainsec .inputbox {
  width: 40%;
}

.emaildetails_mainsec .management_contntbox .add_tags_mainsec .inputbox .form-group {
  margin-bottom: 0px;
}

.emaildetails_mainsec .btn_mainsec .btn_style::before {
  background-color: #000;
}

.emaildetails_mainsec .btn_mainsec .btn_style:hover {
  color: #fff;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .csv_upload_boxsec {
  padding-top: 0px;
}

.dashboard_mainbox.add-email lable {
  color: #d477c9;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 5px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .form-control {
  background-color: initial;
  border: 1px solid #dedede !important;
  border-radius: 4px;
  box-shadow: none !important;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  outline: none !important;
  padding: 8px 20px;
}

.dashboard_mainbox.add-email .upload_box .upload_txt .contnt_txt p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .or_textsec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  position: relative;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .or_textsec::before {
  content: "";
  background-color: #d477c9;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%);
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .or_textsec h3 {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
  background-color: #fff;
  position: relative;
  z-index: 9;
  padding: 10px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .campaign_input_box .custom_checkbox {
  padding-left: 30px;
  color: #000;
  font-size: 16px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .campaign_input_box .custom_checkbox .checkmark {
  background-color: #ffebfd !important;
  border: 1px solid #d477c9 !important;
  border-radius: 3px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 20px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .campaign_input_box .custom_checkbox .checkmark::after {
  border-color: #d477c9;
}

.dashboard_mainbox.add-email .btn_mainsec .btn_style:hover {
  color: #fff;
}

.dashboard_mainbox.add-email .btn_mainsec .btn_style::before {
  background-color: #000;
}

.add-email .addnew_emails_mainsec .gsuite_mansec {
  margin-bottom: 30px;
}

.add-email .addnew_emails_mainsec .or_devider_sec {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.add-email .addnew_emails_mainsec .or_devider_sec::before {
  content: "";
  background-color: #d477c9;
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  width: 100%;
}

.add-email .addnew_emails_mainsec .or_devider_sec h5 {
  background-color: #fff;
  color: #000;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
  padding: 10px;
  position: relative;
  z-index: 9;
  width: auto;
  display: inline-block;
}

.create_domain_mainsec {
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.create_domain_mainsec > .row {
  width: 100%;
}

.create_domain_mainsec .form_mainsec {
  padding-top: 0px;
}

.create_domain_mainbox .stepbox_mainsec {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.create_domain_mainbox .stepbox_sec ul {
  list-style: none;
  margin-bottom: 30px;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.create_domain_mainbox .stepbox_sec ul li {
  width: 25%;
  text-align: center;
  position: relative;
}

.create_domain_mainbox .stepbox_sec ul li::after {
  content: "";
  background-color: #d477c9;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0px;
  top: 17px;
  z-index: 9;
}

.create_domain_mainbox .stepbox_sec ul li:first-child::after {
  width: 50%;
  right: 0px;
  left: inherit;
}

.create_domain_mainbox .stepbox_sec ul li:last-child::after {
  width: 50%;
}

.create_domain_mainbox .stepbox_sec ul li .step_contnt .imgbox {
  display: inline-block;
  background-color: #fff;
  border: 2px solid #d477c9;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 28px;
  position: relative;
  z-index: 99;
}

.create_domain_mainbox .stepbox_sec ul li .step_contnt img {
  width: 22px;
  height: auto;
}

.create_domain_mainbox .stepbox_sec ul li.active .step_contnt .imgbox {
  background-color: #d477c9;
  border: 2px solid #d477c9;
}

.create_domain_mainbox .stepbox_sec ul li.active .step_contnt .imgbox img {
  filter: brightness(2);
}

.create_domain_mainbox .stepbox_sec ul li .step_contnt h3 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.create_domain_mainbox .bottom_contnt {
  display: flex;
  flex-wrap: wrap;
}

.create_domain_mainbox .bottom_contnt .contnt_sec {
  width: 65%;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec {
  width: 35%;
  text-align: right;
}

.create_domain_mainbox .bottom_contnt .contnt_sec h4 {
  color: #d477c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 5px;
}

.create_domain_mainbox .bottom_contnt .contnt_sec p {
  color: #626262;
}

.create_domain_mainbox .bottom_contnt .contnt_sec p a {
  color: #000;
  text-decoration: underline;
}

.create_domain_mainbox .bottom_contnt .contnt_sec p a:hover {
  color: #d477c9;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec .btn_style::before {
  background-color: #000;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec .btn_style {
  color: #fff;
  margin-left: 10px;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec .btn_style.backbtn {
  background-color: #fff;
  border: 1px solid #d477c9;
  color: #d477c9;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec .btn_style.backbtn::before {
  background-color: #d477c9;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec .btn_style.backbtn:hover {
  color: #fff;
}

.create_domain_mainbox .domaingenerator_sec {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.create_domain_mainbox .domaingenerator_sec .contnt_sec {
  width: 35%;
  padding-right: 15px;
}

.create_domain_mainbox .domaingenerator_sec .contnt_sec h4 {
  color: #d477c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 5px;
}

.create_domain_mainbox .domaingenerator_sec .contnt_sec p {
  color: #626262;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox {
  width: 65%;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form {
  width: 60%;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form.numbersdomains_input {
  width: 40%;
  padding-left: 20px;
  position: relative;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form .form-label {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form .form-control {
  background-color: initial;
  border: 1px solid #dedede !important;
  border-radius: 4px;
  box-shadow: none !important;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  outline: none !important;
  padding: 8px 20px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form.numbersdomains_input .inputsec {
  position: relative;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form.numbersdomains_input .inputsec .form-control {
  padding-right: 60px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form.numbersdomains_input span {
  position: absolute;
  top: 11px;
  right: 7px;
  color: #5c5c5c;
  font-size: 13px;
  font-weight: 400;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .btnbox .btn_mainsec .btn_style::before {
  background-color: #000;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .btnbox .btn_mainsec .btn_style {
  color: #fff;
}

.create_domain_mainbox .domain_listbox {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.create_domain_mainbox .domain_listbox .contnt_box {
  width: 35%;
  padding-right: 15px;
}

.create_domain_mainbox .domain_listbox .domain_list_input {
  width: 65%;
}

.create_domain_mainbox .domain_listbox .contnt_box h5 {
  color: #d477c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 5px;
}

.create_domain_mainbox .domain_listbox .contnt_box p {
  color: #626262;
}

.create_domain_mainbox .domain_listbox .contnt_box ul {
  padding-left: 0px;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.create_domain_mainbox .domain_listbox .contnt_box ul li {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  font-family: "Inter", sans-serif;
  margin-bottom: 10px;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 15px;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .group-form {
  width: 50%;
  margin-bottom: 10px;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .group-form .form-control {
  background-color: initial;
  border: 1px solid #dedede !important;
  border-radius: 4px;
  box-shadow: none !important;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  outline: none !important;
  padding: 8px 20px;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .btnbox .btn_style {
  background-color: #d6ffd6;
  border: 1px solid #0b0;
  border-radius: 6px;
  color: #0b0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 25px;
  outline: none;
  box-shadow: none;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .btnbox .btn_style:hover {
  background-color: #0b0;
  border: 1px solid #0b0;
  color: #fff;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .remove_box .btn_style {
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 6px;
  color: #ce0000;
  padding: 8px 15px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .remove_box .btn_style:hover {
  background-color: #ce0000;
  border: 1px solid #ce0000;
  color: #fff;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box.checkavailability .btn_mainsec .btn_style::before {
  background-color: #000;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box.checkavailability .btn_mainsec .btn_style {
  color: #fff;
  padding: 10px 30px;
}

.create_domain_mainbox .createnewdomains {
  margin-bottom: 50px;
}

.create_domain_mainbox .dmarcforwarding {
  margin-bottom: 50px;
}

.create_domain_mainbox .contactdetails {
  margin-bottom: 50px;
}

.create_domain_mainbox .dmarcforwarding .bottom_contnt {
  align-items: start;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.alertmsg p {
  color: #f04a4a !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 0px;
}

.create_domain_mainbox .contactdetails .bottom_contnt {
  align-items: start;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.create_domain_mainbox .custom_checkbox .label_txt {
  color: #626262;
  font-size: 15px;
}

.create_domain_mainbox .custom_checkbox .checkmark {
  background-color: #ffebfd !important;
  border: 1px solid #d477c9 !important;
  border-radius: 3px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 20px;
}

.create_domain_mainbox .custom_checkbox .checkmark::after {
  border-color: #d477c9;
}

.create_domain_mainbox .contactfields .contact_formsec {
  width: 65%;
}

.create_domain_mainbox .contactfields .contact_formsec .formsec {
  width: 100%;
}

.create_domain_mainbox .contactfields .contact_formsec .formbox {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.create_domain_mainbox .contactfields .contact_formsec .formbox .group-form {
  width: 50%;
  padding-right: 25px;
  margin-bottom: 20px;
}

.create_domain_mainbox .contactfields .contact_formsec .formbox .group-form .form-label {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.create_domain_mainbox .contactfields .contact_formsec .formbox .group-form .form-control {
  background-color: initial;
  border: 1px solid #dedede !important;
  border-radius: 4px;
  box-shadow: none !important;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  outline: none !important;
  padding: 8px 20px;
}

.create_domain_mainbox .checkoutbox .bottom_contnt {
  align-items: start;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox {
  width: 65%;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox {
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 20px;
  width: 75%;
  box-shadow: 0px 2px 3px 0px #d7d7d7;
  margin-bottom: 20px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .titlebox {
  margin-bottom: 15px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .titlebox h4 {
  color: #d477c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 0px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .contactinfo {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .contactinfo .leftbox {
  width: 50%;
  padding-right: 20px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .contactinfo .rightbox {
  width: 50%;
  padding-left: 20px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .contactinfo .leftbox .info {
  margin-bottom: 20px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .contactinfo .info label {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  margin-bottom: 5px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .contactinfo .info span {
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  width: 100%;
  display: inline-block;
  word-break: break-all;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .pricebox .pricetxt {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .pricebox .pricetxt h4 {
  color: #d477c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 10px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .pricebox .pricetxt h3 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 10px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .pricebox .pricetxt p {
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .pricebox .domainlistbox ul {
  padding-left: 0px;
  list-style: none;
  margin-bottom: 0px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .pricebox .domainlistbox ul li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .pricebox .domainlistbox ul li span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .pricebox .domainlistbox ul li .domaintxt {
  padding-right: 15px;
}

.email_listing_mainsec .select_email_type .tabbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}

.email_listing_mainsec .select_email_type .radio_custmbtn {
  padding-left: 0px;
  display: flex;
  margin-bottom: -1px;
}

.email_listing_mainsec .select_email_type .radio_custmbtn .txt {
  background-color: #ffeefd;
  border: 1px solid #ddd;
  border-radius: 0;
  box-shadow: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  outline: none;
  padding: 11px 30px;
}

.email_listing_mainsec .select_email_type .radio_custmbtn input:checked + .txt {
  background-color: #d477c9;
  border: 1px solid #d477c9;
  color: #fff;
}

.email_listing_mainsec .select_email_type .radio_custmbtn input:checked + .txt:hover {
  background-color: #d477c9;
  border: 1px solid #d477c9;
  color: #fff;
}

.select_email_type .tabcontnt_box .table_mainsec .table tbody tr td label {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
}

.select_email_type .tabcontnt_box .table_mainsec .table tbody tr td .form-control {
  color: #000;
}

.select_email_type .tabcontnt_box .table_mainsec .table tbody tr td textarea.form-control {
  height: 43px;
}

.select_email_type .tabcontnt_box .table_mainsec .table tfoot tr td {
  padding: 25px 0px;
  border-bottom: none;
}

.select_email_type .tabcontnt_box .table_mainsec .table tfoot tr td .btn_mainsec {
  text-align: left;
}

.select_email_type .tabcontnt_box .table_mainsec .table tfoot tr td .btn_mainsec .btn_style {
  color: #fff;
}

.select_email_type .tabcontnt_box .table_mainsec .table tfoot tr td .btn_mainsec .btn_style::before {
  background-color: #000;
}

.select_email_type .tabcontnt_box .predefine_template .title_mainsec {
  margin-bottom: 25px;
}

.select_email_type .tabcontnt_box .btn_mainsec .btn_style {
  color: #fff;
}

.select_email_type .tabcontnt_box .btn_mainsec .btn_style::before {
  background-color: #000;
}

.select_email_type .tabcontnt_box .commonmsg .title_mainsec {
  margin-bottom: 25px;
}

.select_email_type .tabcontnt_box .commonmsg .formbox .form-control {
  color: #000;
}

.email_accounts_minsec .bottom_contnt {
  align-items: start;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.email_accounts_minsec .important_contnt .contnt_sec h3 {
  color: #d477c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 5px;
}

.email_accounts_minsec .important_contnt .contnt_sec h4 {
  color: #d477c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 5px;
  margin-top: 30px;
}

.email_accounts_minsec .emailaccount_input {
  margin-top: 25px;
}

.email_accounts_minsec .emailaccount_input .formsec .group-form {
  width: 50%;
}

.email_accounts_minsec .emailaccount_input .formsec .group-form .label_txt {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 8px;
  display: block;
}

.email_accounts_minsec .emailaccount_input .formsec .group-form .form-control {
  background-color: initial;
  border: 1px solid #dedede !important;
  border-radius: 4px;
  box-shadow: none !important;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  outline: none !important;
  padding: 8px 20px;
}

.create_domain_mainbox .payment_mainsec .bottom_contnt {
  align-items: start;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.create_domain_mainbox .payment_mainsec .payment_form .btn_mainsec .btn_style::before {
  background: #000;
}

.create_domain_mainbox .payment_mainsec .payment_form .btn_mainsec .btn_style {
  color: #fff;
}

.email_listing_mainsec .filter_boxsec .filtersec {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: end;
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.email_listing_mainsec .filter_boxsec .filtersec .form-control {
  width: 40%;
  color: #000;
}

.email_listing_mainsec .filter_boxsec .filtersec .btn_mainsec .btn_style {
  padding: 8px 20px;
  color: #fff;
}

.email_listing_mainsec .filter_boxsec .filtersec .btn_mainsec .btn_style::before {
  background-color: #000;
}

.pagination button {
    background: #d477c9 !important;
    color: #fff !important;
    border: 1px solid !important;
}

.pagination button.active {
    background: transparent !important;
    color: #000 !important;
    border: none !important;
}

.email_checker_mainsec .email_verification_box .form_mainsec .form-group {
  margin-bottom: 0px;
}

.email_checker_mainsec .email_verification_box .form_mainsec .form-group .form-control {
  color: #000;
}

.email_validat_msg {
  color: #db0000;
  font-size: 16px;
}

.email_checker_mainsec .btn_mainsec .btn_style::before {
  background-color: #000;
}

.email_checker_mainsec .btn_mainsec .btn_style {
  color: #fff;
}


/* ============= Responsive Css Start ============= */

@media only screen and (max-width: 767px) {
.container {
  padding: 0 20px;
} 

.menu_sidebar {
  padding: 10px;
  width: 60px;
  min-width: 60px;
}

.menu_sidebar .logo_box img {
  width: 40px;
}

.profile_box_mainsec {
  left: 12px;
  width: 40px;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle .profile_imgbox {
  width: 40px;
  height: 40px;
}

.dashboard_body {
  padding-left: 60px;
  min-height: 400px;
}

.dashboard_body .tophead_mainsec {
  padding: 10px 20px 10px;
}

.header_mainsec .navbar .navbar-brand {
  padding: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  width: 50%;
}

.header_mainsec .navbar .navbar-brand img {
  width: 100%;
  height: auto;
}

.footer_mainsec .logo_box {
  margin-bottom: 20px;
  text-align: center;
}

.footer_mainsec .logo_box img {
  width: 260px;
  height: auto;
}

.footer_mainsec {
  padding-top: 35px;
  padding-bottom: 25px;
}

.dashboard_body .services_mainbox .services_box {
  margin-bottom: 20px;
}

.services_mainbox .services_box a {
  text-align: center;
}

.profile_box_mainsec .nav-item.dropdown .nav-link {
  padding: 0 0px;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle .name_txt {
  display: none;
}

.emailchecker_mainsec {
  padding-top: 50px;
  padding-bottom: 50px;
}

.step_mainsec {
  padding-top: 0px;
  padding-bottom: 0px;
}

.step_mainsec .title_box h1 {
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 30px;
}

.step_mainsec .step_boxsec {
  padding-right: 0px;
  margin-bottom: 35px;
}

.uploadbox_mainsec .fileinput_sec .btn_mainsec .btn_style {
  margin-bottom: 15px;
  margin-left: 0px;
}

.menu_sidebar .menu_box ul li a .name_txt {
  display: none;
}

.table_mainsec .table tbody tr td a {
  margin-bottom: 6px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .csv_upload_boxsec .btn_mainsec .btn_style {
  margin-left: 0px;
  margin-top: 8px;
  font-size: 14px;
  padding: 10px 17px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .or_textsec {
  min-height: 100px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .or_textsec::before {
  height: 1px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
}

.menu_sidebar .logo_box {
  margin-bottom: 0px;
}

.campaigns .campaign_list .tophead_mainsec .btn_mainsec {
  text-align: left;
}

.campaigns .campaign_list .tophead_mainsec .btn_mainsec .btn_style {
  margin-left: 0px !important;
}

.campaign_details {
  padding: 0px;
}

.campaign_details .tophead_mainsec .title_mainsec h2 {
  font-size: 24px;
  line-height: 32px;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox .innerbox {
  border-right: none;
  border-bottom: 1px solid #cec0ff;
  padding: 30px 30px;
  width: 100%;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox {
  margin-right: 0px;
  padding: 15px;
  width: 100%;
  margin-bottom: 15px;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox .emailperformance_box .contnt_sec p {
  font-size: 16px;
  line-height: 26px;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox .title_sec h4 {
  font-size: 22px;
  line-height: 30px;
}

.emaildetails_mainsec .emailoutbound._mainbox {
  margin-bottom: 25px;
}

.overview_contntbox .graph_mainsec .inner_box.inboxspam_graph {
  width: 100%;
  margin-bottom: 25px;
}

.overview_contntbox .graph_mainsec .inner_box.warmupemail_graph {
  width: 100%;
}

.emaildetails_mainsec .general_contntbox .smtpsettings_form {
  width: 100%;
}

.emaildetails_mainsec .form-group .form-control {
  font-size: 14px;
}

.title_mainsec h2 {
  font-size: 20px;
  line-height: 27px;
}

.create_domain_mainsec {
  padding: 20px 10px 30px;
}

.create_domain_mainsec > .row {
  width: 100%;
  margin: 0px;
}

.create_domain_mainbox .stepbox_sec ul {
  align-items: start;
}

.create_domain_mainbox .stepbox_sec ul li {
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
}

.create_domain_mainbox .stepbox_sec ul li::after {
  background-color: #d477c9;
  height: 100%;
  left: 17px;
  top: 25px;
  width: 2px;
}

.create_domain_mainbox .stepbox_sec ul li:first-child::after {
  left: 18px;
  right: 0px;
  width: 2px;
  height: 100%;
}

.create_domain_mainbox .stepbox_sec ul li:last-child::after {
  width: 2px;
  height: 0px;
}

.create_domain_mainbox .stepbox_sec ul li .step_contnt {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.create_domain_mainbox .stepbox_sec ul li .step_contnt h3 {
  font-size: 14px;
  line-height: 20px;
  width: 75%;
}

.create_domain_mainbox .bottom_contnt .contnt_sec {
  width: 100%;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec {
  width: 100%;
}

.create_domain_mainbox .bottom_contnt .contnt_sec p {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec .btn_style {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 15px;
  margin-bottom: 5px;
}

.create_domain_mainbox .domaingenerator_sec .contnt_sec {
  padding-right: 0px;
  width: 100%;
  margin-bottom: 10px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox {
  width: 100%;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form {
  width: 100%;
  margin-bottom: 15px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form .form-label {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form.numbersdomains_input {
  padding-left: 0px;
  width: 100%;
  margin-bottom: 25px;
}

.btn_mainsec .btn_style {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 15px;
}

.create_domain_mainbox .domaingenerator_sec .contnt_sec p {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form .form-control {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domain_listbox .contnt_box {
  padding-right: 0px;
  width: 100%;
  margin-bottom: 15px;
}

.create_domain_mainbox .domain_listbox .contnt_box p {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domain_listbox .contnt_box ul li {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domain_listbox .domain_list_input {
  width: 100%;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box {
  gap: 8px;
  margin-bottom: 25px;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .group-form {
  margin-bottom: 0px;
  width: 100%;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .group-form .form-control {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 15px;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .btnbox .btn_style {
  font-size: 14px;
  line-height: 22px;
  padding: 8px 15px;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .remove_box {
  margin-top: 0px !important;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box.checkavailability .btn_mainsec .btn_style {
  padding: 8px 15px;
}

.create_domain_mainbox .custom_checkbox .label_txt {
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.create_domain_mainbox .contactfields .contact_formsec {
  width: 100%;
}

.create_domain_mainbox .contactfields .contact_formsec .formbox .group-form {
  margin-bottom: 15px;
  padding-right: 0px;
  width: 100%;
}

.create_domain_mainbox .contactfields .contact_formsec .formbox .group-form .form-label {
  font-size: 14px;
  line-height: 23px;
  display: block;
}

.create_domain_mainbox .contactfields .contact_formsec .formbox .group-form .form-control {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 15px;
}

.email_accounts_minsec .important_contnt .contnt_sec p {
  font-size: 14px;
  line-height: 23px;
}

.email_accounts_minsec .important_contnt .contnt_sec h4 {
  margin-top: 20px;
}

.email_accounts_minsec .emailaccount_input .formsec .group-form {
  width: 100%;
}

.email_accounts_minsec .emailaccount_input .formsec .group-form .label_txt {
  font-size: 14px;
  line-height: 23px;
}

.email_accounts_minsec .emailaccount_input .formsec .group-form .form-control {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 15px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox {
  width: 100%;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox {
  padding: 15px;
  width: 100%;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .contactinfo .leftbox {
  padding-right: 0px;
  width: 100%;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox .contactinfo .rightbox {
  padding-left: 0px;
  width: 100%;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .pricebox .pricetxt h3 {
  font-size: 28px;
  line-height: 37px;
}
}


@media only screen and (min-width: 768px) and (max-width: 1024px)  {
.services_mainbox .services_box .title_box h3 {
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}

.services_mainbox .services_box .icon_box img {
  width: 55px;
}

.emailchecker .emailchecker_mainsec .step_mainsec {
  padding-top: 0px;
  padding-bottom: 20px;
}

.emailchecker .emailchecker_mainsec .step_mainsec .step_boxsec {
  padding-right: 10px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .form-control {
  font-size: 13px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .csv_upload_boxsec .btn_mainsec .btn_style {
  margin-left: 0px;
  margin-top: 8px;
  font-size: 14px;
  padding: 10px 17px;
}

.campaign_details {
  padding: 0px;
}

.emaildetails_mainsec .overview_contntbox .summary_mainbox .innerbox {
  padding: 15px 20px;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox .title_sec h4 {
  font-size: 20px;
  line-height: 29px;
}

.emaildetails_mainsec .emailoutbound._mainbox .innerbox {
  margin-right: 15px;
  min-height: 225px;
  padding: 15px;
  width: 48%;
}

.overview_contntbox .graph_mainsec .inner_box.inboxspam_graph {
  width: 40%;
}

.overview_contntbox .graph_mainsec .inner_box.warmupemail_graph {
  padding-left: 20px;
}

.emaildetails_mainsec .general_contntbox .smtpsettings_form {
  width: 100%;
}

.emaildetails_mainsec .form-group .form-control {
  font-size: 14px;
}

.campaign_details .tophead_mainsec .title_mainsec h2 {
  font-size: 20px;
  line-height: 30px;
}

.dashboard_mainbox.add-email .addnew_emails_mainsec .csv_upload_boxsec .btn_mainsec button {
  margin-right: 10px;
}

.search-domain .create_domain_mainsec {
  padding: 30px 15px 50px;
}

.search-domain .create_domain_mainsec .form_mainsec {
  width: 100%;
}

.search-domain .create_domain_mainsec > .row {
  width: 100%;
  margin: 0px;
}

.create_domain_mainbox .stepbox_sec ul li .step_contnt h3 {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .bottom_contnt .contnt_sec {
  width: 100%;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec {
  width: 100%;
  margin-top: 20px;
}

.create_domain_mainbox .domaingenerator_sec .contnt_sec {
  padding-right: 0px;
  width: 100%;
  margin-bottom: 15px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox {
  width: 100%;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form .form-label {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domain_listbox .contnt_box {
  padding-right: 0px;
  width: 100%;
  margin-bottom: 15px;
}

.create_domain_mainbox .domain_listbox .domain_list_input {
  width: 100%;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .btnbox .btn_style {
  font-size: 14px;
  padding: 7px 15px;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .group-form .form-control {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 15px;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box.checkavailability .btn_mainsec .btn_style {
  color: #fff;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domain_listbox .contnt_box p {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domain_listbox .contnt_box ul li {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .bottom_contnt .contnt_sec p {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .bottom_contnt .btn_mainsec .btn_style {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domaingenerator_sec .contnt_sec p {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .btnbox .btn_mainsec .btn_style {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .custom_checkbox .label_txt {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .contactfields .contact_formsec {
  width: 100%;
}

.create_domain_mainbox .contactfields .contact_formsec .formbox .group-form .form-label {
  font-size: 14px;
  line-height: 23px;
}

.create_domain_mainbox .contactfields .contact_formsec .formbox .group-form .form-control {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 15px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form .form-control {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 15px;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox {
  width: 100%;
}

.create_domain_mainbox .checkoutbox .checkoutdetails .checkoutinfobox .infobox {
  width: 100%;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .group-form {
  margin-bottom: 15px;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox .formsec .formbox .custom_checkbox {
  width: 100%;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .group-form {
  width: 47%;
}

.create_domain_mainbox .stepbox_sec ul {
  align-items: start;
}

.email_accounts_minsec .emailaccount_input .formsec .group-form {
  width: 80%;
}

.email_accounts_minsec .important_contnt .contnt_sec p {
  font-size: 14px;
  line-height: 23px;
}

.email_accounts_minsec .emailaccount_input .formsec .group-form .label_txt {
  font-size: 14px;
  line-height: 23px;
}

.email_accounts_minsec .emailaccount_input .formsec .group-form .form-control {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 15px;
}
}

@media only screen and (min-width: 1600px) and (max-width: 2500px)  {
.create_domain_mainbox .domaingenerator_sec .contnt_sec {
  width: 25%;
}

.create_domain_mainbox .domaingenerator_sec .domain_inputbox {
  width: 45%;
}

.create_domain_mainbox .domain_listbox .contnt_box {
  width: 25%;
}

.create_domain_mainbox .domain_listbox .domain_list_input {
  width: 45%;
}

.create_domain_mainbox .contactfields .contact_formsec {
  width: 75%;
}

.create_domain_mainbox .contactfields .contact_formsec .formsec {
  width: 70%;
}

.create_domain_mainbox .domain_listbox .domain_list_input .input_box .group-form {
  width: 58%;
}
}

/* ============= Responsive Css End ============= */

































